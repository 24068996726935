html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}
.root{
  display: flex;
  flex-flow: column;
  height: 100%;
}

.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}