.login-form,
.signup-form {
  max-width: 350px;
  margin: auto;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}
.dropdown > .dropdown-toggle:active {
  pointer-events: none;
}
a {
color: #f7f7f7;
}
html,
body {
  
  height: 100%;
  margin: 0;
}
.rs-divider-horizontal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.rs-form {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 10% !important;
  margin-right: 10% !important;
} /*
div[role=row]:nth-child(even) {
  background-color: #f2f2f2;
}*/
.rs-drawer-body {
  padding: 0px !important;
}

.striped-table .rs-table-row:nth-child(even) .rs-table-cell {
  background-color:  #DBEAEA;
}


.rs-navbar-item{
  color: #f7f7f7;
}
/*
.rs-navbar{
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;}
.rs-content{
  flex: 1 1 auto;
}
.fixed-top {

}*/


.rs-picker-default .rs-picker-toggle{
  z-index: 0 !important;
}







.editor-class {
  background-color:white;
  border: 1px solid #ccc;
}
.toolbar-class {
  display: inline-block;
}